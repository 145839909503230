import axios from 'axios'
import { apiUrl ,userId,organizeId,loginName,password} from '@/config'

const request = axios.create({
  withCredentials: false,
  baseURL: apiUrl
})

request.interceptors.request.use(
  config => {
  config.headers['userId'] =getStorage("miseUserId") || userId
  config.headers['organizeId'] =getStorage("miseOrganizeId")||organizeId
  config.headers['Authorization'] = 'Bearer ' + getStorage("Admin-Token")
  return config
  },
  error => {
    console.log('error', error)
    return Promise.reject(new Error(error).message)
  }
)

request.interceptors.response.use(
  response => {
     console.log('.....', response)
	  if (response.data.code === 401) { //授权过期
	  var params={
	  	 loginName:loginName,
	  	 password:password
	   }
	   axios.post(apiUrl+'/login',params).then(res =>{ console.log(res,'res')
		   setStorage('Admin-Token',res.data.token)
		    //  location.href = '/';
	   })
	
	  }
    return response.data
  },
  error => {
    console.log('error', error)
    return Promise.reject(new Error(error).message)
  }
)

export default request
