import Vue from "vue"
import {mapState,mapMutations} from 'vuex'


Vue.mixin({
    data(){
        return {
            photosShow:false,
            leftboxshow:false,
            simgshow:false,
            p1itemshow:false,
            p2itemshow:false,
            p3itemshow:false,
            p4itemshow:false,
            p5itemshow:false,
            p6itemshow:false,
            stitle3show:false,
            right3imgshow:false,
            left3imgshow:false,
            hometxtShow:false,
        }
    },
    computed:{
        ...mapState(['lang','headShow']),
    },
    mounted(){
        window.addEventListener("scroll",()=>{
            this.handleScroll()
            setTimeout(()=>{
                this.handleScrollAni()
            },500)
        })
    },
    methods:{
        handleScroll(){
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop ;
            if(scrollTop>100){
                this.changeHeadShow(true)
            }else{
                this.changeHeadShow(false)
            }
        },
        ...mapMutations(['changeAllLang','changeMenuShow','changeHeadShow']),
        // gdjz (div, offset, callback) {
        //     let dom = this.$refs[div] // 等同于document.querySelector('.earth') true
        //     if(dom){
        //         var a,b,c,d;
        //         d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
        //         a = eval(d + offset)
        //         b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        //         c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        //         if( b + c > a ) {
        //         callback && callback()
        //         }
        //     }
        // },
        handleScrollAni() { // 实现当滚动到指定位置，触发动画
            // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
            // this.gdjz('earth', 'earth animated bounceInDown', 50)
            let _this = this
            let refArray = [
              {ref: 'photos', show: 'photosShow',offset:600},
              {ref: 'leftbox', show: 'leftboxshow'},
              {ref: 'srigntbox', show: 'simgshow'},
              {ref: 'simg', show: 'simgshow'},
              {ref: 'p1item', show: 'p1itemshow'},
              {ref: 'p2item', show: 'p2itemshow'},
              {ref: 'p3item', show: 'p3itemshow'},
              {ref: 'p4item', show: 'p4itemshow'},
              {ref: 'p5item', show: 'p5itemshow'},
              {ref: 'p6item', show: 'p6itemshow'},
              {ref: 'stitle3', show: 'stitle3show'},
              {ref: 'right3img', show: 'right3imgshow'},
              {ref: 'left3img', show: 'left3imgshow'},
              {ref: 'hometxt', show: 'hometxtShow'},
              
            ]
            refArray.forEach((r,i) => {
              _this.gdjz(r.ref, 20 , () => {
                _this[r.show] = true
              })
            })
          },
          gdjz (div, offset, callback) {
            let dom = this.$refs[div] // 等同于document.querySelector('.earth') true
            if(dom){
              var a,b,c,d;
              d = dom.offsetTop // 元素距离相对父级的高度，这里父级指的是body
              a = eval(d + offset)
              b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
              c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
              if( b + c > a ) {
                callback && callback()
              }
            }
        }
    },
})