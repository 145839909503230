
const apiUrl = 'https://oa.whsrsoft.com/api'; // 测试
const fileUrl = 'https://oa.whsrsoft.com';
const userId=3387;
const organizeId=1;
const loginName='Hannah';
const password='111111'
//缓存数据的全局方法
window.getStorage = (key) =>{
   return sessionStorage.getItem(key)
}
window.setStorage = (key,value) =>{
    sessionStorage.setItem(key,value)
}
window.removeStorage = (key) =>{
    sessionStorage.removeItem(key)
}
export { apiUrl,fileUrl,userId,organizeId,loginName,password}
