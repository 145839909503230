import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
      path:"/",
      redirect:"/home"
    },
    {
      path:"/home",
      name:"home",
      component:()=>import("@/views/Home.vue")
    },
    { 
      // 政治法规
      path:"/politicalLaw",
      name:"politicalLaw",
      component:()=>import("@/views/PoliticalLaw.vue")
    },
    {
      // 政治法规(详情)
      path:"/politicalDeatil/:queryId(\\d+)/:dataId(\\d+)",
      name:"politicalDeatil",
      component:()=>import("@/views/PoliticalDeatil.vue")
    },
    {
      // 企业风采
      path:"/enterpriseStyle",
      name:"enterpriseStyle",
      component:()=>import("@/views/EnterpriseStyle.vue")
    },{
      // 企业风采（详情）
      path:"/enterpriseStyledeatail/:queryId(\\d+)/:dataId(\\d+)",
      name:"enterpriseStyledeatail",
      component:()=>import("@/views/EnterpriseStyledeatail.vue")
    },
    {
      // 专家介绍
      path:"/expertIntroduction",
      name:"expertIntroduction",
      component:()=>import("@/views/ExpertIntroduction.vue")
    },
    {
       // 专家介绍-详情
       path:"/expertdetails/:queryId(\\d+)/:dataId(\\d+)",
       name:"expertdetails",
       component:()=>import("@/views/Expertdetails.vue")
    },
    {
      // 协会介绍
      path:"/association",
      name:"association",
      component:()=>import("@/views/Association.vue")
    },
    {
      // 联系我们
      path:"/connection",
      name:"connection",
      component:()=>import("@/views/Connection.vue")
    },
   //  {
   //    // 搜索页面
   //    path:"/searchList/:queryId(\\d+)/:formId(\\d+)/:date(\\d+)",
	  // name:"searchList",
   //    component:()=>import("@/views/searchList.vue")
   //  },
	{
	  // 搜索页面
	  path:"/searchList",
	  name:"searchList",
	  component:()=>import("@/views/searchList.vue")
	},
	{
	  // 搜索页面详情
	  path:"/searchDetails/:queryId(\\d+)/:dataId(\\d+)",
	  name:"searchDetails",
	  component:()=>import("@/views/searchDetails.vue")
	},
    {
      path:"*",
      redirect:{
        name:"/"
      }
    },

]

const router = new VueRouter({
  routes
})


router.afterEach((to,from,next) => { 
	
    window.scrollTo(0,0);
});
export default router
