 import Head from "./head.vue"
 import Menu from "./menu.vue"
 import Foot from "./foot.vue"
import AppHead from "./apphead.vue"
 

export default {
    install: function(Vue) {
        Vue.component("Head",Head),
        Vue.component("Menu",Menu),
        Vue.component("Foot",Foot),
        Vue.component("AppHead",AppHead)
        
    }
}