<template>
    <header class="header">
        <div class="title-box flexb">
            <div class="title-style">湖北省制造业与互联网融合发展联盟<br/>
                                        湖北省中小企业数字化转型发展联盟</div>
            <div class="flexa headone">
                <div class="m r10"><img src="../assets/images/head/phone.png" alt="" srcset=""></div>
                <div class="flexc mr10 tel">027-8848569</div>
                <div class="search-box flexb mr10">
					<el-select v-model="selectValue" placeholder="请选择" class="select" @visible-change="selectVisibleChange">
					    <el-option
					    v-for="item in options"
					    :key="item.value"
					    :label="item.label"
					    :value="item.value">
					    </el-option>
					</el-select>
                    <input type="text" v-model="inputValue" placeholder="请输入关键字">
                  <div><img class="searchicon" src="../assets/images/head/serch-icon.png" alt="" srcset="" @click="search"></div>
                </div>
                <div class="oa-style flexc" @click="toOA()">管理员入口</div>
            </div>
        </div>
        <div class="head-box"   >
            <div class="box">
                <ul class="links">
                    <li class="hitem" :class="headShow?'hbai':'htou'"   >
                        <router-link  :to="{name:'home'}"  >
                            {{$t("首页")}}
                        </router-link>
                    </li>
                    <li class="hitem" :class="headShow?'hbai':'htou'"  >
                        <router-link :to="{name:'politicalLaw'}" >
                            {{$t("政策法规")}}
                        </router-link>
                    </li>
                    <li class="hitem" :class="headShow?'hbai':'htou'"  >
                        <router-link :to="{name:'enterpriseStyle'}">
                            {{$t("联盟风采")}}
                        </router-link>
                    </li>
                    <li class="hitem" :class="headShow?'hbai':'htou'"  >
                        <router-link :to="{name:'expertIntroduction'}" >
                            {{$t("专家介绍")}}
                        </router-link>
                    </li>
                    <li class="hitem" :class="headShow?'hbai':'htou'"  >
                        <router-link :to="{name:'association'}" >
                            {{$t("联盟介绍")}}
                        </router-link>
                    </li>
                    <li class="hitem" :class="headShow?'hbai':'htou'"  >
                        <router-link :to="{name:'connection'}" >
                            {{$t("联系我们")}}
                        </router-link>
                    </li>
                </ul>
           </div>
           

        </div>
    </header>
</template>


<script>
import {vantLocales} from "@/main.js"
import {EventBus} from "../utils/event-bus.js";
export default {
	data(){
		return {
			redirect: undefined,
			inputValue:'',
			searchStatus:'',
			selectValue:'politicalLaw',
			queryId:'',
			formId:'',
			options:[{value:'politicalLaw',label:'政策法规',queryId:'588',formId:'715'},
					{value:'enterpriseStyle',label:'企业风采',queryId:'589',formId:'717'},
					// {value:'expertIntroduction',label:'专家介绍',queryId:'590',formId:'718'},
			],
		}
		  
	},

    methods:{
		toOA(){
			window.open('https://oa.whsrsoft.com/#/index')
		},
        selectVisibleChange(val){
            this.selectDownVisible = val
        },
		search(){ 
			let that = this;
			console.log(that.inputValue,'搜索全局')
			if(that.selectValue=='politicalLaw'){
			
				   var queryId= 595
				   var formId=824
				
			}
			if(that.selectValue=='enterpriseStyle'){
			
				   var queryId= 596
				   var formId=825
				
			}
			if(that.selectValue=='expertIntroduction'){
				
				   var queryId= 590
				   var formId=718
			}
		
			//console.log(that.$router,'this.$router====')
		    var obj={
				inputValue:that.inputValue,
				queryId:queryId,
				formId:formId
			}
			setStorage('searchParams', JSON.stringify(obj))
			EventBus.$emit('searchParams',JSON.stringify(obj))
			console.log(this.$router.currentRoute,'当前路由')
			  const currentRoutePath = this.$router.currentRoute.path
		  if(currentRoutePath !=='/searchList'){
			  this.$router.push({path:"/searchList"}).catch(err => {
			                 console.log(err,'err')
			  			  })
		  }
		   
			// that.$router.push({path:"searchList/"+queryId+"/"+formId+"/"+Date.now(),
			// 	query:{
			// 		inputValue:encodeURIComponent(this.inputValue),
			// 	}
			// })
			
			
			 //  let routeName = ''
			 //  let url="searchList/"+queryId+"/"+formId+"/"+Date.now()
			 // const currentRoutePath = this.$router.currentRoute.path
			 //  routeName = "searchList"
			 //  if(url !== currentRoutePath ) {
				//   this.$router.push({path:"searchList/"+queryId+"/"+formId+"/"+Date.now(),
				//   query:{
				//   				  inputValue:encodeURIComponent(this.inputValue),
				  				  
				//   }
				//   })
			 //  }
		}
    },
}
</script>
<style lang="scss">
.mr10{
    margin-right: 10px;
}
.header{
    background-image:url(../assets/images/head/head.png) ;
    background-color: #1367c5;
    .title-box{
       width:1200px;
      margin:0 auto;
    //   padding-left:150px;
      display: flex;
      color: white;
      margin-top: 30px;
      margin-bottom: 60px;
      justify-content: space-between;
      .title-style{
        font-size: 25px;
        font-weight: bold;
        line-height: 45px;
      }
      .search-box{
        
        border-radius: 20px;
        background-color: #d7eaff;
        padding: 5px 10px;
        padding-right: 10px;
        height:45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input{
            border: none;
            background-color: #d7eaff;
            height:35px;
            line-height: 35px;
			color: #000;
            
        }
        .searchicon{
            margin-top:-3px;
            cursor: pointer;
        }
		.select{
			width: 120px;
		}
      }
      .oa-style{
        padding: 0 5px;
        height: 45px;
        background-color: #fdb333;
        border-radius: 6px;
        cursor: pointer;
      }

    }
}

.head-box{
    margin-top: 30px;
}
.headone{
    display:flex;
    align-items: center;
    .m{
        margin-right:15px;
    }
    .tel{
        font-weight: 500;
        font-size:30px;
    }
}
</style>