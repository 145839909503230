import Vue from "vue"
Vue.directive("shuibowen",{
    bind(el,binding){
        $(el).css({
            position: 'relative',
            overflow: 'hidden',
        })
       
        el.addEventListener("click",function(e){
           
           
            var posX = $(el).offset().left,
                posY = $(el).offset().top,
                buttonWidth = $(el).width(),
                buttonHeight = $(el).height();

                $(el).append("<span class='ripple'></span>");
                
                if (buttonWidth >= buttonHeight) {
                    buttonHeight = buttonWidth;
                } else {
                    buttonWidth = buttonHeight;
                }
                var x = e.pageX - posX - buttonWidth / 2;
                var y = e.pageY - posY - buttonHeight / 2;
                $(".ripple").css({
                    width: buttonWidth,
                    height: buttonHeight,
                    top: y + 'px',
                    left: x + 'px'
                }).addClass("rippleEffect");
                
                // 动画运行结束
                el.addEventListener("webkitAnimationEnd",function(){
                    $(el).find('.ripple').remove()
                })
                // setTimeout(()=>{
                //     $(el).find('.ripple').remove()
                // },401)
        })
    }
})