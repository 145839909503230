const cache = {}

const themeAction = {
    dark(){
        if(!cache.dark){
            cache.dark = require('../styles/index-dark.scss');
        }
        return cache.dark;
    },
    light(){
        if(!cache.light){
            cache.light = require('../styles/index-light.scss');
        }
        return cache.light ;
    }
}

let current = null;


async function setTheme(theme) {
    if (themeAction[theme]) {
        const style = await themeAction[theme]();
        if (current) {
            current.unref();
        }
        // style.ref();
        current = style;
    }
}
window.setTheme = setTheme;
export default setTheme;