import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuShow:false,
    headShow:false,
    lang:localStorage.getItem("langData") || "en"
  },
  mutations: {
      changeHeadShow(state,payload){
        state.headShow = payload
      },
      changeMenuShow(state,payload){
        state.menuShow = payload;
      },
      changeAllLang(state,payload){
        state.lang = payload;
      }
  },
  actions: {
  },
  modules: {
  }
})
