import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import request from "./utils/request.js";
import { apiUrl ,userId,organizeId,loginName,password} from '@/config'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import "@/common/mixins"

Vue.config.productionTip = false

// 引入插件  Sweet弹框 
// import VueSweetalert2 from './common/sweetalert2'
// Vue.use(VueSweetalert2);

import swal from 'sweetalert2'
// 添加实例方法
Vue.prototype.$swal = swal

import "@/common/index.js" 

  // 切换白天和黑夜 
  import setTheme from "@/common/theme";
  setTheme(localStorage.getItem('themeStyle')||'light');

import Vant from "vant"
import 'vant/lib/index.css';
Vue.use(Vant)

// 全局组件注册 
import Components from './components';
Vue.use(Components);

// swiper 样式  
import "swiper/dist/css/swiper.min.css"
// Svg
import "@/styles/fonts/iconfont.js"

import VueI18n from 'vue-i18n'/*用 vue-i18n 切换中英文*/
import {Locale} from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'

Vue.use(VueI18n)    // 国际化 



// langData 初始化   默认英文 
var langData = localStorage.getItem("langData");
if(langData){
  // 有数据但是不是 cn . en th 
    if(langData!="cn"&&langData!="en"){
      localStorage.setItem("langData","en");   
      store.commit('changeAllLang',"en")
    }
}else{
   localStorage.setItem("langData","en"); 
   store.commit('changeAllLang',"en")
}

const messages={
  en:{
    ...require('@/assets/languages/en.json'),   // 本地翻译文件 
  },
  cn:{
    ...require('@/assets/languages/cn.json'),
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem("langData") || 'en',  // 指定默认语言(中文)  默认 中文 cn 繁体中文：hk 英文：en 泰语：th   
  messages
})
// locale.i18n((key, value) => i18n.t(key, value));   //  $t() 翻译 

function vantLocales (lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'cn') {
    Locale.use(lang, zhCN)
  }
}


export  {i18n,vantLocales}

// 初始化设定好 vant 语言 
vantLocales(i18n.locale)

const en = {
    message: {
        "datepicker": {
            "now": "Now",
            "today": "Today",
            "cancel": "Cancel",
            "clear": "Clear",
            "confirm": "OK",
            "selectDate": "Select date",
            "selectTime": "Select time",
            "startDate": "Start Date",
            "startTime": "Start Time",
            "endDate": "End Date",
            "endTime": "End Time",
            "prevYear": "Previous Year",
            "nextYear": "Next Year",
            "prevMonth": "Previous Month",
            "nextMonth": "Next Month",
            "year": "",
            "month1": "January",
            "month2": "February",
            "month3": "March",
            "month4": "April",
            "month5": "May",
            "month6": "June",
            "month7": "July",
            "month8": "August",
            "month9": "September",
            "month10": "October",
            "month11": "November",
            "month12": "December",
            "week": "week",
            "weeks": {
                "sun": "Sun",
                "mon": "Mon",
                "tue": "Tue",
                "wed": "Wed",
                "thu": "Thu",
                "fri": "Fri",
                "sat": "Sat"
            },
            "months": {
                "jan": "Jan",
                "feb": "Feb",
                "mar": "Mar",
                "apr": "Apr",
                "may": "May",
                "jun": "Jun",
                "jul": "Jul",
                "aug": "Aug",
                "sep": "Sep",
                "oct": "Oct",
                "nov": "Nov",
                "dec": "Dec"
            }
        }
    },
};

const cn = {
    message: {
        datepicker: {
            now: '此刻',
            today: '今天',
            cancel: '取消',
            clear: '清空',
            confirm: '确定',
            selectDate: '选择日期',
            selectTime: '选择时间',
            startDate: '开始日期',
            startTime: '开始时间',
            endDate: '结束日期',
            endTime: '结束时间',
            prevYear: '前一年',
            nextYear: '后一年',
            prevMonth: '上个月',
            nextMonth: '下个月',
            year: '年',
            month1: '1 月',
            month2: '2 月',
            month3: '3 月',
            month4: '4 月',
            month5: '5 月',
            month6: '6 月',
            month7: '7 月',
            month8: '8 月',
            month9: '9 月',
            month10: '10 月',
            month11: '11 月',
            month12: '12 月',
            // week: '周次',
            weeks: {
                sun: '日',
                mon: '一',
                tue: '二',
                wed: '三',
                thu: '四',
                fri: '五',
                sat: '六'
            },
            months: {
                jan: '一月',
                feb: '二月',
                mar: '三月',
                apr: '四月',
                may: '五月',
                jun: '六月',
                jul: '七月',
                aug: '八月',
                sep: '九月',
                oct: '十月',
                nov: '十一月',
                dec: '十二月'
            }
        },
    },
};

const AdminToken = getStorage('Admin-Token');
	if(!AdminToken){
		//removeStorage('Admin-Token')
		var params={
			 loginName:loginName,
			 password:password
		 }
		 request.post('/login',params).then(res =>{
			 setStorage('Admin-Token',res.token)
		 })
	}


new Vue({
  router,
  store,
  i18n,
  en,
  cn,
  render: h => h(App)
}).$mount('#app')
