<template>

  <div v-cloak  @click="changeMenuShow(false)" class="main-box">
    <Head class="apphide"></Head>
    <AppHead class="appshow"></AppHead>
    <transition name="menumove"   >
      <Menu v-show="menuShow" class="appshow"></Menu>  
    </transition>
    <router-view class="subjgbox" ></router-view>
    <Foot></Foot>
  </div>
</template>


<script>

import {mapState,mapMutations} from "vuex"
export default {
    computed:{
      ...mapState(['menuShow'])
    },
    methods:{
     
        ...mapMutations(['changeMenuShow'])
    },
    mounted(){ }
}
</script>

<style>
.main-box{
  padding-top:185px;
}
@media (max-width: 750.01px){
  .main-box{
    padding-top:50px;
  }
}
</style>