<template>
    <div class="sidemenu">
        <div>
            <i @click.stop="changeMenuShow(false)" class="iconfont iconfanhui hide"></i>
            <ul >
                 <li >
                    <router-link :to="{name:'home'}">
                        {{$t("首页")}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'politicalLaw'}">
                        {{$t("政策法规")}}
                    </router-link>
                </li>

                <li>
                    <router-link :to="{name:'enterpriseStyle'}">
                        {{$t("企业风采")}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'expertIntroduction'}">
                        {{$t("专家介绍")}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'association'}">
                        {{$t("协会介绍")}}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'connection'}">
                        {{$t("联系我们")}}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapMutations,mapState} from "vuex"
import {vantLocales} from "@/main.js"
export default {
    methods:{
        ...mapMutations(['changeMenuShow','changeAllLang']),
    },
    computed:{
        ...mapState(['menuShow','lang'])
    },
    data(){
        return {
            
        }
    },
    watch:{

    }
}
</script>

