// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/head/head.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".app-head[data-v-7316c0a0]{color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.title[data-v-7316c0a0]{font-size:15px;font-weight:600;line-height:50px;display:flex;justify-content:center;align-items:center;text-align:left}", "",{"version":3,"sources":["E:/duwei/网站/development-alliance/src/components/E:/duwei/网站/development-alliance/src/components/E:/duwei/网站/development-alliance/src/components/apphead.vue"],"names":[],"mappings":"AACA,2BACI,UAAA,CACA,wDAAA,CAEJ,wBACI,cAAA,CACA,eAAA,CACA,gBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA","file":"apphead.vue","sourcesContent":["\r\n.app-head{\r\n    color: white;\r\n    background-image: url('../assets/images/head/head.png');\r\n}\r\n.title{\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n    line-height: 50px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: left;\r\n}\r\n"]}]);
// Exports
module.exports = exports;
