<template>
    <header  class="app-head" >
        <div class="title">湖北省中小企业数字化转型发展联盟</div>
        <div>
            <i @click.stop="changeMenuShow(true)" class="iconfont iconmenu menu" :style="{color:headShow?'#aaa':'white'}"></i>
        </div>
    </header>
</template>

<script>
import {mapMutations,mapState} from "vuex"
export default {
    computed:{
        ...mapState(['menuShow'])
    },
    methods:{
        ...mapMutations(['changeMenuShow']),
    },
}
</script>
<style lang="scss" scoped>
.app-head{
    color: white;
    background-image: url('../assets/images/head/head.png');
}
.title{
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}
</style>
